/* eslint-disable @typescript-eslint/no-explicit-any */
import router from "@/router"; //shortcut to src
import { Module } from "vuex";
import apiRequest from "@/services/apiRequest";
import jwt_decode from "jwt-decode";

import ErrorHandler from "../../repositories/clients/ErrorResponseHandle";


const authGuard: Module<any, any> = {
  namespaced: true,
  state: {
    loggedIn: false,
    loggedError: false,
    user: null,
    accessToken: null,
    rules: null,
    expireTime: null,
  },

  getters: {
    getAccessToken(state: any) {
      return state.accessToken;
    },
    isExpired(state: any) {
      return new Date(state.expireTime) < new Date();
    },
    isLoggedError(state: any) {
      return state.loggedError;
    },
    getId(state: any) {
      return state.user?._id;
    },
    getName(state: any) {
      return state.user?.fullname || "";
    },
  },

  mutations: {
    loggedIn(state: any, payload) {
      state.loggedIn = true;
      state.accessToken = payload.token;
      state.rules = payload.rules;
      state.expireTime = payload.expire_time;

      const userInfo: any = jwt_decode(payload.token);
      state.user = userInfo.user;

      location.replace("/");
    },

    loggedError(state: any, bolean) {
      state.loggedError = bolean;
    },

    loggedOut(state: any) {
      state.loggedIn = false;
      state.accessToken = null;
      state.rules = null;
      state.user = null;

      router.push("/login");
    },

    setCurrentUser(state: any, payload) {
      state.user = payload;
    },

    // changePass(state: any, data) {
    //   if ("last_login" in data) {
    //     if (data.last_login === null) {
    //       location.replace("/change-password");
    //     } else {
    //       location.replace("/");
    //     }
    //   } else {
    //     location.replace("/change-password");
    //     return;
    //   }
    // },
  },

  actions: {
    login({ commit }, payload) {
      apiRequest
        .request({
          url: "/users/authentication",
          method: "post",
          data: payload,
        })
        .then((response) => {
          if (response.data.code == 1) {
            commit("loggedIn", response.data.data);
            commit("loggedError", false);
            // commit("changePass", response.data.data);
          }
        })
        .catch((error) => {
          commit("loggedError", true);
          ErrorHandler(error.response.data.sys_message);
        });
    },
    async logout({ commit }) {
      apiRequest
        .request({
          url: "/users/logout",
          method: "post",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then(() => {
          commit("loggedOut");
        })
        .catch((error) => {
          console.log("loggedOut", error);
          ErrorHandler(error.response.data.sys_message);
          commit("loggedOut");
        });
    },
    async changePass({ commit }, payload) {
      apiRequest
        .request({
          url: "/users/" + this.state.authGuard.user._id + "/change-password",
          method: "put",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          router.push("/home");
        })
        .catch((error) => {
          console.log("changePass", error);
        });
    },
  },
};

export default authGuard;
