const mapData = (data) => {
  let results = data.map((item) => ({
    label: item.username + " - " + item.fullname,
    value: item._id,
  }));
  return results;
};
const mapArr = (data) => {
  let results = data.map((item,index) => ({
    value: index,
    label: item,
  }));
  return results;
};
const revertArr = (data) => { 
  let results = data.map((item) => (
    item.value
  ));
  return results;
};
export default {
  mapData,
  mapArr,
  revertArr
};
