import Client from "./clients/AxiosClient";
import Export from "./clients/ExportFile";

const resource = "/inventory-trans";
const report = "inventory-reports/exchange";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  create(data) {
    return Client.post(`${resource}`, data);
  },
  update(data, id) {
    return Client.put(`${resource}/${id}`, data);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  export() {
    return Export.get(`${resource}/export`);
  },
  upload(data) {
    return Client.post(`${resource}/upload`, data);
  },
  getReport(params) {
    return Client.get(`${report}`, { params: params });
  },
  getExcelReport(params) {
    return Export.get(`${report}/file`, { params: params });
  }
};
