import { ability } from "@/services/ability";
import Constant from "../enums/constant";
/* eslint-disable @typescript-eslint/no-explicit-any */
export default ({ to, store, next }: any) => {
  if (!store.state.authGuard.loggedIn || store.getters["authGuard/isExpired"]) {
    localStorage.removeItem("vuex");
    next("/login");
    return false;
  }
  // if (
  //   !store.state.authGuard.user?.is_changed_password &&
  //   to.path !== "/change-password"
  // )
  //   next("/change-password");
  if (store.state.authGuard.user?.phone.match(`_${Constant.UNVERIFIED}`)?.index)
    next("/verify-user");

  if (!to.meta.subject) {
    console.log("zo day hk");
    next("/403");
  }

  if (
    !ability.can("read", to.meta.subject) &&
    !/\/courses/.test(to.path) &&
    to.path !== "/home"
  ) {
    next("/403");
  }

  next();
};
